














import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import LoadWidgetComponent from '@/components/LoadWidgetComponent.vue';
import UiPageWidget from '@/models/graphql/UiPageWidget';

@Component({
  components: {
    LoadWidgetComponent,
  },
})
export default class WizardStepWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  private get currentStep(): UiPageWidget {
    return this
      .$store
      .getters[`${this.getWidgetStorePath(this.widget.parentWidget)}/currentStep`];
  }

  created(): void {
    this.setDataConfig();
  }
}
